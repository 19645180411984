<template>
  <div class="body">
    <div class="flex_able margin-top30">
      <div class="flex_able font-size30 font-color1B1B1B font-weightBold line-height44 margin-left30 "
           style="color:#FFF1CE;">
        请选择提取类型
      </div>
      <div class="flex_1"></div>
      <title-arrow
          @click.native="skipCarveRecord"
          class="margin-right30"
          textColor="#ffffff"
          lineHeight="var(--36)"
          textSize="var(--26)"
          imageWidth="var(--14)"
          imageHeight="var(--24)"
          marginLeft="var(--8)"
          imageUrl="https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/fad30107-245c-4473-8a7a-e13843d9ec8b.png"
          :text="redTopNumberDetailRecord"/>
    </div>
    <div @click="showChoiceRedMoneyType = true" class="margin-left30 margin-right30 margin-top28 flex_able"
         style="border-radius: var(--24);background:#14274B;padding-left: var(--28);padding-right: var(--28);height: var(--96);">
      <div class="font-colorFFFFFF font-size28 font-weightBold flex_1">
        {{ redTopNumberDetail }}
      </div>
      <img style="width: var(--28);height: var(--16);"
           src="https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/c3c2b677-ce73-4a15-a9e8-47045c106630.png">
    </div>
    <div class="flex_able font-size30 font-color1B1B1B font-weightBold line-height44 margin-left30 margin-top30"
         style="color:#FFF1CE;">
      {{ placeholderWithdrawalAddress }}
    </div>

    <div class="margin-left30 margin-right30 margin-top28 flex_able"
         style="border-radius: var(--24);background:#14274B;padding-left: var(--28);padding-right: var(--28);">
      <van-field ref="textInput" style="flex: 1" v-model="withdrawalAddress" :placeholder="placeholderWithdrawalAddress"
                 clearable
                 placeholder-class='placeholder-input'/>

      <div @click="handlePaste" class="margin-left16 font-size28 font-color666666" style="height: 100%;">粘贴</div>
    </div>
    <div class="flex_able font-size30 font-colorFFFFFF font-weightBold line-height44 margin-left30 margin-top30"
         style="color:#FFF1CE;">
      提现数量
    </div>

    <div class="margin-left30 margin-right30 margin-top28 flex_able" style="border-radius: var(--24);background:#14274B;padding-left: var(--28);
          padding-right: var(--28);">
      <van-field style="flex: 1" v-model="withdrawalNumber" placeholder="请输入提取数量" clearable type="number"
                 placeholder-class='placeholder-input'/>
      <div @click="inputAll" class="margin-left16 font-size28 font-color666666" style="height: 100%;">全部</div>
    </div>
    <div class="flex_able margin-top30 margin-left30 margin-right30">
      <div class="font-size28 font-colorFFFFFF">
        可提数量：{{ balance }} {{ this.configObject.fromAccountCoinName }}
      </div>
      <div class="flex_1"></div>
      <div class="font-size28 font-colorFFFFFF">
        手续费：{{ gasAmount }} {{ this.configObject.feeAssetTypeName }}
      </div>
    </div>
    <div v-if="false" class="flex_able font-size30 font-color1B1B1B font-weightBold line-height44 margin-left30 margin-top30"
         style="color:#FFF1CE;">
      到账数量
    </div>
    <div v-if="false" class="margin-left30 margin-right30 margin-top28 flex_able"
         style="border-radius: var(--24);background:#14274B;padding-left: var(--28);padding-right: var(--28);">

      <div class="flex_1 flex_able font-colorFFFFFF font-size28" style="height: var(--96);">
        {{
          EncryptUtils.isEmpty(withdrawalNumber) ? "0" : EncryptUtils.formatDecimal2NoZero(withdrawalNumber - gasAmount)
        }}
      </div>
      <div @click="handlePaste" class="margin-left16 font-size28 font-colorFFFFFF" style="height: 100%;">
        {{ this.configObject.fromAccountCoinName }}
      </div>
    </div>
    <div class="flex_able margin-top70 margin-leftAuto margin-rightAuto">
      <div v-if="false" @click="skipTransferCarveUp" class="transferCarveUp font-size30 font-weightBold flex_center" style="color: #FFDC99;">
          划转
      </div>
      <div @click="extract" class="flex_center font-size30 font-color1B1B1B font-weightBold"
           style="background: linear-gradient(218deg, #FFF1CD 0%, #FFDA93 100%);width: var(--650);height: var(--88);border-radius: var(--44);">
        确认提现
      </div>
    </div>

    <div class="flex_center" style="padding-top: var(--30);">
      <title-arrow
          @click.native="skipExtractRecord"
          text-size="var(--26)"
          image-width="var(--14)"
          image-height="var(--24)"
          text-color="#FFFFFF"
          line-height="var(--36)"
          image-margin-left="var(--10)"
          image-url="https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/9dd5be39-9032-44e1-ae71-fc07dd74a63d.png"
          text="提取记录"/>
    </div>
    <div class="flex_able margin-left30 margin-top30">
      <img style="width: var(--28);height: var(--28);"
           src="https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/34b9efcf-ad1a-4407-af4f-a29ba0135366.png"/>
      <span class="font-size28 font-colorFFFFFF margin-left12">说明</span>
    </div>
    <div style="display: flex;flex-direction: column" class="margin-left30 margin-right30 margin-top30">
      <div class="font-size28 font-weight400 line-height42" style="color:#DDDDDD;"
           v-html="activityRole"></div>
    </div>
    <!-- 密码弹窗组件 -->
    <PwdDialog ref="controlPwdDialog" @handleChange="pwdCallback"/>
    <van-action-sheet v-model="showChoiceRedMoneyType" :actions="actionsMoneyType" @select="onSelectMoneyType"
                      cancel-text="取消"/>
  </div>
</template>

<script>

// import {Api} from "@/utils/net/Api";
// import {Toast} from "vant";

import {Constants} from "@/utils/constants";
import {Api} from "@/utils/net/Api";
import {Toast} from "vant";
import PwdDialog from "@/components/PwdDialog.vue";
import TitleArrow from "@/components/TitleArrow.vue";
import EncryptUtils from "@/utils/EncryptUtils";

export default {
  components: {TitleArrow, PwdDialog},
  computed: {
    Constants() {
      return Constants
    },
    EncryptUtils() {
      return EncryptUtils
    }
  },
  data() {
    return {
      // placeholderWithdrawalAddress: "请输入币安支付ID",
      placeholderWithdrawalAddress: "请输入提现地址",
      configObject: {},
      activityRole: "",
      withdrawalNumber: "",
      withdrawalAddress: "",
      arrivalQuantity: "0",
      imgUrl: "",
      balance: "",
      coinName: "",
      assetType: "",
      usdtNumber: "",
      gasAmount: 0.0,
      gasAmountMap: new Map(),
      showChoiceRedMoneyType: false,//显示发红包钱类型
      actionsMoneyType: [
        {
          name: Constants.X_COIN_NAME,
          assetType: Constants.X_COIN,
        }, {
          name: Constants.DOG_COIN,
          assetType: Constants.DOG_COIN_TYPE
        }, {
          name: Constants.SHI_COIN,
          assetType: Constants.SHI_COIN_TYPE
        }, {
          name: Constants.WEN_COIN,
          assetType: Constants.WEN_COIN_TYPE
        }
      ],
      redTopNumberDetail: "",
      redTopNumberDetailRecord: ""
    }
  },
  watch: {
    withdrawalNumber(value, newValue) {
      console.log("value: " + value)
      console.log("newValue: " + newValue)
      if (value > this.configObject.maxAmount) {
        Toast("提取数量不高于" + this.configObject.maxAmount)
        this.withdrawalNumber = this.configObject.maxAmount
      } else {
        this.queryFee(value)
      }
    },
  },
  created() {
    //开始5
    try {
      //设置标题
      document.title = "提取"
      window.Android.setTitleStyle(JSON.stringify({
        titleBarBackgroundColor: '#091732',
        textColor: '#FFFFFF',
        showTitleBar: "true"
      }));
      window.Android.setStatusBarStyle(JSON.stringify({
        isDark: "false",
        statusBarBgColor: "#091732",
        showStatusBar: "true"
      }));
    } catch (error) {
      console.log("常规错误:" + error)
    }
    this.queryPageConfiguration()
    this.onSelectMoneyType(this.actionsMoneyType[0])
    window.getClipboardContent = this.getClipboardContent;
  },
  methods: {
    //请求 banner 配置
    queryPageConfiguration() {
      let params = {
        positionType: 5001,
      }
      Api.queryPageConfiguration(params).then((result) => {
        console.log('data....', result)
        if (result.code === 100) {
          try {
            result.data.pageConfigurations.forEach((itemPage) => {
              console.log(itemPage)
              let detail = itemPage.titleDetail
              if (detail == "walletExtractHasNewB" || EncryptUtils.isEmpty(detail)) {
                this.actionsMoneyType.push({name: itemPage.name, assetType: itemPage.value})
              }
            })
          } catch (error) {
            console.log("常规错误:" + error)
          }
        } else {
          this.this.activityImage = ""
        }
      })
    },
    // 币种类型选择
    onSelectMoneyType(itemTemp) {
      let that = this;
      console.log(itemTemp.name)
      that.showChoiceRedMoneyType = false;
      that.redTopNumberDetail = itemTemp.name
      that.redTopNumberDetailRecord = itemTemp.name + " 明细记录"
      that.assetType = itemTemp.assetType
      this.queryWithdrawalChainConfig()
    },
    getClipboardContent(content) {
      //调用原生获取剪切板内容
      this.withdrawalAddress = content
    },
    handlePaste() {
      //调用原生获取剪切板内容
      window.Android.checkClipboardContent()
    },
    inputAll() {
      this.withdrawalNumber = this.balance
    },
    //显示密码弹窗
    showPwdDialogFunction() {
      this.$refs.controlPwdDialog.controlPwdDialogFunction(true);
    },
    //隐藏密码弹窗
    hidePwdDialogFunction() {
      this.$refs.controlPwdDialog.controlPwdDialogFunction(false);
    },
    //密码组件回调 经过各种判断后返回加密后文字
    pwdCallback(value) {
      this.exchangeDo(value)
    },
    //跳转到划转页面
    skipTransferCarveUp() {
      //跳转到详情
      this.$router.push({
        path: '/transferToCoin',
        query: {
          assetType: this.assetType,
        }
      });
    },
    extract() {
      if (this.withdrawalAddress == "") {
        Toast("请输入提取地址")
        return;
      }
      if (this.withdrawalNumber == "") {
        Toast("请输入提取数量")
        return;
      }
      if (this.withdrawalNumber < this.configObject.minAmount) {
        console.log("转赠数量不低于")
        Toast("提取数量不低于" + this.configObject.minAmount)
        return
      }
      if (this.withdrawalNumber > this.configObject.maxAmount) {
        console.log("转赠数量不高于")
        Toast("提取数量不高于" + this.configObject.maxAmount)
        return
      }
      // if (this.assetType != this.configObject.feeAssetType) {
      //   //第1种情况 提币类型和手续费资产类型不同时. 单独判断手续费是否够
      //   if (this.configObject.balanceFee < this.gasAmount) {
      //     Toast("手续费不足")
      //     return;
      //   }
      // } else {
      //   if (this.balance - this.withdrawalNumber - this.gasAmount < 0) {
      //     Toast("余额不足")
      //     return;
      //   }
      // }
      this.exchangeDo()
      // this.showPwdDialogFunction()
    },
    exchangeDo() {
      let params = {
        walletAddress: this.withdrawalAddress,
        amount: this.withdrawalNumber,
        assetType: this.assetType,
        fromAccountTypeReal: this.assetType,
      }
      Api.addWithdrawalChainRecord(params).then((result) => {
        if (result.code === 100) {
          Toast("提取中")
          this.withdrawalNumber = ""
          this.withdrawalAddress = ""
          this.hidePwdDialogFunction()
          this.queryWithdrawalChainConfig()
          //跳转到提取记录
          this.skipExtractRecord()
        } else {
          Toast(result.msg);
        }
      })
    },
    skipExtractRecord() {
      //跳转到详情
      this.$router.push({
        path: '/walletExtractRecord',
        query: {
          assetType: this.assetType,
        }
      });
    },
    skipCarveRecord() {
      //跳转到瓜分记录
      this.$router.push({
        path: '/myCoinRecord',
        query: {
          assetType: this.assetType,
          title:this.redTopNumberDetailRecord
        }
      });
    },
    //查询对应的转出列表
    queryWithdrawalChainConfig() {
      let params = {
        assetType: this.assetType,
        fromAccountTypeReal: this.assetType,
      }
      Api.queryWithdrawalChainConfig(params).then((result) => {
        if (result.code === 100) {
          //弹窗内容
          let activityRole = result.data?.config.confDes
          this.activityRole = activityRole?.replace(/\n/g, "<br>")
          this.configObject = result.data?.config
          this.balance = this.configObject.balance
          if (this.assetType == Constants.X_COIN) {
            this.withdrawalAddress = result.data?.walletAddress
          } else {
            this.withdrawalAddress = ""
          }
        } else {
          Toast(result.msg);
        }
      })
    },
    //查询手续费
    queryFee(amountTemp) {
      if (this.gasAmountMap.has(amountTemp)) {
        this.gasAmount = this.gasAmountMap.get(amountTemp)
      } else {
        let params = {
          amount: amountTemp,
          assetType: this.assetType,
          fromAccountTypeReal: this.assetType,
        }
        Api.queryFee(params).then((result) => {
          if (result.code === 100) {
            this.gasAmount = result.data.fee
            this.gasAmountMap.set(amountTemp, this.gasAmount)
          } else {
            Toast(result.msg);
          }
        })
      }
    },
    showTransferOutQuantity(item) {
      this.defaultCommonAmountItem = item
      this.controlPopup('popupExplain')
    },
    goToDetail(item) {
      //跳转到详情
      this.$router.push({
        path: '/assetDetails',
        query: {
          assetType: item.assetType,
          coinName: item.coinName,
          balance: item.balance,
          imgUrl: item.imgUrl
        }
      });
    },
  },
}
</script>

<style scoped>
.body {
  background-color: #091732;
  width: 100vw;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/deep/ .van-tab--active {
  font-weight: bold;
}

/deep/ .van-tab {
  margin-left: var(--25);
  flex: 0;
}

/deep/ .van-tab__text {
  text-align: center;
  width: var(--100);
}

/deep/ .van-tabs__line {
  width: var(--44);
}

/deep/ .van-tabs__nav--line {
  padding-bottom: var(--16);
}

.walletItem {
  width: 100vw;
  height: 100%;
}

.line_height_style {
  width: var(--8);
  height: var(--32);
  background: #026AFC;
  border-radius: var(--4);
}

/* 设置背景和padding */
::v-deep .van-cell {
  background-color: transparent;
  padding: 0;
}

/* 设置 placeholder 颜色 */
::v-deep .van-field__control::placeholder {
  color: #999999;
}

/* 输入框高度 增加热区 */
::v-deep .van-field__control {
  height: var(--96);
}

/* 设置field里面的字体大小 */
::v-deep .van-field__control {
  color: #FFFFFF;
  font-size: var(--28);
  font-weight: 400;
  line-height: var(--40);
}

::v-deep .van-cell::after {
  left: 0;
  border: none;
}

.transferCarveUp{
  width: var(--330);
  height: var(--88);
  border-radius: var(--44);
  border: var(--4) solid #FFDC99;
}
</style>
